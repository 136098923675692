import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/Hero/Hero'
import OurClients from '../components/OurClients/OurClients'
import OurVision from '../components/OurVision/OurVision'
import WhatWeDo from '../components/WhatWeDo/WhatWeDo'
import Technologies from '../components/Technologies/Technologies'
import FeaturedWebsites from '../components/FeaturedWebsites/FeaturedWebsites'
import BottomNav from '../components/BottomNav/BottomNav'
import FeaturedVideo from '../components/FeaturedVideo/FeaturedVideo'
import ClientPerks from '../components/ClientPerks/ClientPerks'

const IndexPage = () => (
  <Layout homePage>
    <SEO
      title="Emersoft - Hire Trusted Developers. We Help Brands Design, Develop & Deliver Effective Web Solutions And Drive More Revenue."
      keywords={[`Emersoft`, `application`, `react`, `digital agency`]}
      isHomePage
    />
    <Hero />
    <OurClients heading="Trusted By" small />
    {/*<OurVision />*/}
    <FeaturedWebsites />
    <ClientPerks />
    <WhatWeDo />
    <FeaturedVideo />
    <Technologies grey />
    <BottomNav
      additionalClass="bottom-nav--home-page"
      heading="Services"
      firstLink="/services/websites"
      firstHeading="Websites/Design"
      firstSubHeading="Let's get started"
      secondLink="/services/hire-developers"
      secondHeading="Proactive Developers"
      secondSubHeading="Let's get started"
    />
  </Layout>
)

export default IndexPage
