import React from 'react'

function WhatWeDo() {
  return (
    <section className="what-we-do">
      <div className="container">
        <div className="what-we-do__inner">
          <h2
            className="our-vision__heading
                    shared-styles__section-heading"
          >
            What we do
            <span className="shared-styles__section-line" />
          </h2>

          <div
            className="what-we-do__content
                      flex
                      justify-between
                      flex-wrap
                      items-end"
          >
            <p className="what-we-do__paragraph-primary text--3">
              Craft, Develop, and Expand Your Digital Presence.
            </p>

            <p className="what-we-do__paragraph-secondary text--5">
              We specialize in digital solutions tailored for{' '}
              <span className="what-we-do__underline underline">
                startups, growing businesses, and established enterprises.
              </span>{' '}
              Our approach ensures that every project is not only state-of-the-art but also aligns perfectly with your target audience's expectations and needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
