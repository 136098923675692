import React from 'react'

import { Link } from 'gatsby'

function Hero() {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-section__content">
          <p
            className="hero-section__label
                    shared-styles__section-heading"
          >
            For Companies
          </p>
          <h1 className="hero-section__heading text--1">
            Digital{' '}
            <span className="hero-section__heading-underline underline">
              Simplicity
            </span>
          </h1>
          <p className="hero-section__content-paragraph text--4">
            Proactive Software Developers Leading with AI.  For Your Next-Level Business Growth.
          </p>
          <div className="hero-section__cta">
            <Link
              to="our-work"
              className="hero-section__button
                      btn
                      btn--blue
                      btn__hero"
            >
              See our work
            </Link>
            <Link
              to="/services/hire-developers"
              className="hero-section__button
                      btn
                      btn--yellow
                      btn__hero"
            >
              Hire Developers
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
