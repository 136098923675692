import React from 'react'

import { Link } from 'gatsby'

function SingleWebsite(props) {
  return (
    <article
      className={
        'featured-websites__single-work ' +
        (props.additionalClass ? props.additionalClass : '')
      }
    >
      <div className="container">
        <Link
          to={props.link}
          className={
            'featured-websites__link ' +
            (props.caseStudy ? props.caseStudy : '')
          }
        >
          <div className="featured-websites__link-content">
            <span
              className={
                'featured-websites__link-logo ' +
                (props.logoClass ? props.logoClass : '')
              }
            >
              {props.logo}
            </span>
            <p
              className="featured-websites__btn
                      btn
                      btn--secondary"
            >
              Case study
            </p>
          </div>
        </Link>

        <div className="featured-websites__content">
          {props.category && <p className="hero-section__label shared-styles__section-heading u-text-center">{props.category}</p>}
          <Link to={props.link} className="featured-websites__title-link">
            <h3
              className={
                `featured-websites__single-heading text--3 underline ` +
                (props.isLong ? `featured-websites__single-heading--long` : ``)
              }
            >
              {props.title}
            </h3>
          </Link>
          <p
            className="featured-websites__single-work-paragraph
                    text--5
                    center"
          >
            {props.description}
          </p>
        </div>
      </div>
    </article>
  )
}

export default SingleWebsite
